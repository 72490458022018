import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Loading from '../components/Loading';

export default function SignIn() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    if (username.length < 1) {
      enqueueSnackbar('您必须输入用户名', { variant: 'error' });
      return;
    }

    if (password.length < 1) {
      enqueueSnackbar('您必须输入密码', { variant: 'error' });
      return;
    }
    try {
      setLoading(true)
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/user/signin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ username, password })
      });
      const signInInfo = await response.json();
      if (response.ok) {
        enqueueSnackbar('登录成功', { variant: 'success' })
        localStorage.setItem('token', signInInfo.token);
        setTimeout(() => {
          navigate('/home');
          window.location.reload();
        }, 1500);
      } else {
        enqueueSnackbar(signInInfo.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setLoading(false);
    }
  };

  if (loading) {
    return (<Loading />);
  }

  return (
    <Box sx={{ pt: 8 }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            登 录
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="用户名"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="密码"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color='inherit'
              sx={{ mt: 3, mb: 2 }}
            >
              登录
            </Button>
            <Grid container justifyContent="flex-end" component={Link} to='/signup'>
              <Grid item>
                "没有账户？ 点击注册"
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
