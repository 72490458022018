import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Fab,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import UserStstus from '../components/UserStatus';
import Loading from '../components/Loading';

// 列表样式
const style = {
  p: 0,
  width: '100%',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
}

// 对应显示文本
const keyTextMap = {
  brand: "请选择控制器品牌:",
  model: "请选择型号:",
};

function InAlarm() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selections, setSelections] = React.useState([]);
  const [currentKey, setCurrentKey] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  // 搜索弹窗打开
  const handleClickOpen = () => {
    setOpen(true);
  };

  // 搜索弹窗关闭
  const handleClose = () => {
    setOpen(false);
  };

  // 网络请求数据
  const getData = React.useCallback(async (select) => {
    setIsLoading(true);
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/alarm/data/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token, select })
      });
      const heliData = await response.json();
      if (response.ok) {
        setData(heliData)
        setCurrentKey(Object.keys(heliData)[0])
        setIsLoading(false);
      } else {
        enqueueSnackbar('身份认证失败，请重新登录', { variant: 'error' });
        setTimeout(() => {
          navigate('/signin');
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setIsLoading(false);
    }
  }, [token, setData, setCurrentKey, setIsLoading, navigate, enqueueSnackbar]);

  // 第一次打开请求数据
  React.useEffect(() => {
    getData([])
  }, [getData])

  // Home按钮回调函数
  const handleHomeClick = React.useCallback(async () => {
    setSelections([]);
    getData([]);
  }, [setSelections, getData]);

  // 路径回调函数
  const handlePathClick = React.useCallback((key) => {
    const pathIndex = selections.findIndex(selection => selection.key === key);
    const select = selections.slice(0, pathIndex + 1);
    setSelections(select);
    getData(select);
  }, [selections, setSelections, getData]);

  // 主页面列表回调函数
  const handleMainClick = React.useCallback((key, value) => {
    const select = [...selections, { key, value }];
    setSelections(select);
    if (select.length <= Object.keys(keyTextMap).length + 1) {
      getData(select)
    }
  }, [selections, setSelections, getData]);

  // 路径导航
  function AlarmNav() {
    return (
      <Box sx={{ mt: 1, width: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={style}>
          <Button color="inherit" onClick={handleHomeClick} sx={{ minWidth: 0 }}>
            <HomeIcon sx={{ mb: 0.5, ml: 0.5 }} />
          </Button>
          {selections.map((selection, index) => (
            <Button
              color="inherit"
              onClick={() => handlePathClick(selection.key)}
              key={selection.key}
              sx={{ minWidth: 0 }}
              disabled={index === selections.length - 1}
            >
              {selection.value}
            </Button>
          ))}
        </Breadcrumbs>
        <React.Fragment>
          <Box sx={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}>
            <Fab color="error" aria-label="search" onClick={handleClickOpen}>
              <SearchIcon />
            </Fab>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: async (event) => {
                event.preventDefault();
                const data = new FormData(event.currentTarget);
                const searchContent = data.get('search');
                const searchType = 'alarm'
                try {
                  setIsLoading(true)
                  const csrfToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('csrftoken='))
                    ?.split('=')[1];
                  const response = await fetch(`/api/search/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRFToken': csrfToken,
                    },
                    body: JSON.stringify({ searchContent, searchType, token })
                  });
                  const searchResponse = await response.json();
                  if (response.ok) {
                    setData(searchResponse)
                    setIsLoading(false);
                    setOpen(false);
                  } else {
                    enqueueSnackbar(searchResponse.message, { variant: 'error' });
                    setIsLoading(false);
                    setOpen(false);
                  }
                } catch (error) {
                  enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
                  setIsLoading(false);
                  setOpen(false);
                }
              },
            }}
          >
            <DialogTitle>搜索</DialogTitle>
            <DialogContent>
              <DialogContentText>
                请输入你要搜索的故障代码或故障英文(可以少输入但不要多输入):
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="search"
                name="search"
                type="search"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <IconButton onClick={handleClose} color='error'><CloseIcon /></IconButton>
              <IconButton type="submit" color='success'><CheckIcon /></IconButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Box>
    )
  }

  // 加载中...
  if (isLoading) {
    return (
      <Loading />
    );
  }

  // 搜索显示列表
  if (Object.keys(data)[0] === 'alarm_search') {
    return (
      <Box sx={{ pt: 10 }}>
        <AlarmNav />
        <Paper sx={{ ...style, mt: 1 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 50 }}>品牌</TableCell>
                  <TableCell align='center' style={{ minWidth: 180 }}>型号</TableCell>
                  <TableCell align='center' style={{ minWidth: 100 }}>故障代码</TableCell>
                  <TableCell align='center' style={{ minWidth: 150 }}>故障名称英文</TableCell>
                  <TableCell align='center' style={{ minWidth: 150 }}>故障名称中文</TableCell>
                  <TableCell align='center' style={{ minWidth: 200 }}>故障信息</TableCell>
                  <TableCell align='center' style={{ minWidth: 200 }}>解决方案</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.alarm_search.map((alarm) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={alarm.id}>
                    <TableCell align='center' >{alarm.brand}</TableCell>
                    <TableCell align='center' >{alarm.model}</TableCell>
                    <TableCell align='center' >{alarm.alarm_code}</TableCell>
                    <TableCell align='center' >{alarm.alarm_name_en}</TableCell>
                    <TableCell align='center' >{alarm.alarm_name_zh}</TableCell>
                    <TableCell align='center' >{alarm.alarm_info}</TableCell>
                    <TableCell align='center' >{alarm.alarm_solution}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  // 故障详情页
  if (Object.keys(data)[0] === 'alarms') {
    return (
      <Box sx={{ pt: 8 }}>
        <AlarmNav />
        <Paper sx={{ ...style, mt: 1, height: 600 }}>
          <TableContainer sx={{ height: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 100 }}>故障代码</TableCell>
                  <TableCell align='center' style={{ minWidth: 150 }}>故障名称英文</TableCell>
                  <TableCell align='center' style={{ minWidth: 150 }}>故障名称中文</TableCell>
                  <TableCell align='center' style={{ minWidth: 200 }}>故障信息</TableCell>
                  <TableCell align='center' style={{ minWidth: 200 }}>解决方案</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[currentKey].map((alarm) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={alarm.alarm_code}>
                    <TableCell align='center' >{alarm.alarm_code}</TableCell>
                    <TableCell align='center' >{alarm.alarm_name_en}</TableCell>
                    <TableCell align='center' >{alarm.alarm_name_zh}</TableCell>
                    <TableCell align='center' >{alarm.alarm_info}</TableCell>
                    <TableCell align='center' >{alarm.alarm_solution}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  // 选择内容
  return (
    <Box sx={{ pt: 8 }}>
      <AlarmNav />
      <Container maxWidth="sm">
        <Typography component="h1" variant="h6" >
          {keyTextMap[currentKey]}
        </Typography>
        <List sx={{ ...style, maxHeight: 600, overflow: 'auto', '&::-webkit-scrollbar': { display: 'none', }, }}>
          {data[currentKey].map((item, index) => (
            <React.Fragment key={item}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleMainClick(currentKey, item)}>
                  <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                </ListItemButton>
              </ListItem>
              {index !== data[currentKey].length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Container>
    </Box >
  );
}

export default function Alarm() {
  return (
    <Container component="main" maxWidth="xl">
      <UserStstus>
        <InAlarm />
      </UserStstus>
    </Container>
  );
}
