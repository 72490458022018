import * as React from 'react';
import { Box, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const SvgComponent = ({ partNumber }) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');
  const [svgContent, setSvgContent] = React.useState(null);

  React.useEffect(() => {
    const apiFetchSvg = async () => {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
        const response = await fetch(`/api/heli/data/svg/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ token, partNumber })
        });
        if (response.ok) {
          const data = await response.json();
          setSvgContent(data.svg_content);
        } else {
          setSvgContent(null);
        }
      } catch (error) {
        enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      }
    };
    apiFetchSvg();
  }, [partNumber, enqueueSnackbar, token, setSvgContent]);

  if (svgContent) {
    const modifiedSvgContent = svgContent
      .replace(/font-family:'[^']*'/g, "font-family:'Times New Roman', serif")
      .replace(/onclick="[^"]*"/g, '')
      .replace(/onmouseover="[^"]*"/g, '')
      .replace(/onmouseout="[^"]*"/g, '');

    return (
      <TransformWrapper initialScale={0.7} minScale={0.5} maxScale={5.0} centerOnInit={true}>
        <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
          <Box dangerouslySetInnerHTML={{ __html: modifiedSvgContent }} />
        </TransformComponent>
      </TransformWrapper>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <CircularProgress size={60} color="error" />
    </Box>
  );
};

export default SvgComponent;
