import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

export default function About() {
  return (
    <Box component="main" sx={{ pt: 8 }}>
      <Container maxWidth="xl">
        <Typography variant='h5' align='center' sx={{ p: 1 }}>
          免责声明
        </Typography>
        <Toolbar />
        <Typography variant='body2' sx={{ p: 1 }}>
          感谢您访问网站。在使用本网站之前，请仔细阅读以下免责声明条款。<br /><br />
          信息准确性：我们努力确保网站上提供的所有信息准确无误。然而，我们不对信息的完整性、准确性、可靠性或适用性做任何明示或暗示的陈述或保证。任何依赖于本网站信息的行动均为您个人风险承担。<br /><br />
          网站链接：本网站可能包含指向第三方网站的链接。这些链接仅为方便用户提供。我们对这些第三方网站的内容或可用性不承担任何责任，并且不对其进行背书或认可。<br /><br />
          使用风险：您自行承担使用本网站的风险。我们不对因使用本网站而导致的任何直接、间接、偶然或间接的损失或损害承担责任，包括但不限于利润损失、业务中断、信息丢失或计算机故障。<br /><br />
          法律适用：本免责声明受当地法律管辖，并应根据您所在地的法律进行解释。任何与本网站或其使用相关的纠纷应受所在地的管辖法院裁决。<br /><br />
          条款更新：我们保留随时更新、修改或更改本免责声明的权利。您应定期查看本页面以了解任何变更。继续使用本网站即表示您接受这些变更。<br /><br />
          此项目仅供个人学习和研究，切勿用于商业用途，如有侵权请联系作者删除。所有资料均为公开资料，版权归原公司所有。
        </Typography>
        <Toolbar />
        <Typography align='right' sx={{ p: 1 }}>
          From: 长春合力叉车有限公司.曹玉章<br />
          Email: 635677784@qq.com
        </Typography>
      </Container>
    </Box>
  );
}
