import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import Loading from './components/Loading';

const helicolor = '#DB0000'
const navButtons = [
  { id: 'heli', text: '合力图册' },
  { id: 'lida', text: '力达图册' },
  { id: 'line', text: '线路图纸' },
  { id: 'alarm', text: '故障代码' },
  { id: 'forklift-info', text: '车号查询' },
  { id: 'about', text: '关于我们' },
  { id: 'announcements', text: '公告通知' },
];

export default function Nav() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const checkUserStatus = async () => {
      const token = localStorage.getItem('token');
      if (token === null) {
        return;
      }
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
        const response = await fetch(`/api/user/status/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ token })
        });
        const navData = await response.json();
        setIsLoggedIn(navData.status);
      } catch (error) {
        enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      }
    };

    checkUserStatus();
  }, [enqueueSnackbar]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    const token = localStorage.getItem('token');
    localStorage.removeItem('token');
    if (token === null) {
      return;
    }
    try {
      setLoading(true)
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/user/signout/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token })
      });
      const navData = await response.json();
      if (response.ok) {
        enqueueSnackbar('注销成功', { variant: 'success' })
        setIsLoggedIn(navData.status);
        setTimeout(() => {
          navigate('/home');
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
    }
  }

  if (loading) {
    return (<Loading />);
  }

  return (
    <Box>
      <AppBar position="fixed" color='inherit' sx={{ zIndex: 1000 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            {/* 大屏幕标题 */}
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to='/home'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: helicolor,
                textDecoration: 'none'
              }}
            >
              HELI
            </Typography>

            {/* 点击菜单按钮展示项目 */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navButtons.map(button => (
                  <MenuItem onClick={handleCloseNavMenu} key={button.id}>
                    <Typography
                      textAlign="center"
                      component={Link}
                      to={`/${button.id}`}
                      sx={{
                        textDecoration: 'none',
                        color: "inherit"
                      }}
                    >
                      {button.text}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* 小屏幕标题 */}
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to='/home'
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: helicolor,
                textDecoration: 'none',
              }}
            >
              HELI
            </Typography>

            {/* 大屏幕直接展示的菜单项目 */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {navButtons.map(button => (
                <Button
                  key={button.id}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={`/${button.id}`}
                  sx={{ my: 2, color: 'inherit', display: 'block', textAlign: 'center' }}
                >
                  {button.text}
                </Button>
              ))}
            </Box>

            {/* 点击用户按钮展示项目 */}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {isLoggedIn ? (
                  <Box>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        component={Link}
                        to='/me'
                        sx={{
                          textDecoration: 'none',
                          color: "inherit"
                        }}
                      >
                        我的
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={handleSignOut}
                        sx={{
                          textDecoration: 'none',
                          color: "inherit"
                        }}
                      >
                        注销
                      </Typography>
                    </MenuItem>
                  </Box>
                ) : (
                  <Box>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        component={Link}
                        to='/signin'
                        sx={{
                          textDecoration: 'none',
                          color: "inherit"
                        }}
                      >
                        登录
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        component={Link}
                        to='/signup'
                        sx={{
                          textDecoration: 'none',
                          color: "inherit"
                        }}
                      >
                        注册
                      </Typography>
                    </MenuItem>
                  </Box>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar >
    </Box>
  );
}