import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Loading from './Loading';

// UserStatus 组件，负责检查用户登录状态并显示子组件或重定向到登录页面
const UserStatus = ({ children }) => {
  const navigate = useNavigate(); // 使用 useNavigate 钩子来进行导航
  const { enqueueSnackbar } = useSnackbar(); // 使用 useSnackbar 钩子来显示通知
  const [loading, setLoading] = React.useState(false); // 定义 loading 状态

  // 使用 useEffect 钩子在组件挂载时检查用户状态
  React.useEffect(() => {
    let isMounted = true; // 标记组件是否已挂载

    const checkUserStatus = async () => {
      const token = localStorage.getItem('token'); // 从本地存储获取 token

      if (token === null) { // 如果没有 token，显示错误通知并重定向到登录页面
        if (isMounted) {
          setLoading(true); // 设置加载状态
          enqueueSnackbar('请登录后查看此页面', { variant: 'warning' }); // 显示错误通知
        }

        setTimeout(() => {
          if (isMounted) {
            navigate('/signin'); // 重定向到登录页面
          }
        }, 1500);

        return;
      }

      try {
        if (isMounted) {
          setLoading(true); // 设置加载状态
        }

        // 发送请求到服务器检查用户状态
        const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
        const response = await fetch(`/api/user/status/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ token }) // 发送 token
        });

        const meData = await response.json(); // 解析响应数据

        if (isMounted) {
          if (!meData.status) { // 如果用户状态无效，显示错误通知并重定向到登录页面
            enqueueSnackbar('请登录后查看此页面', { variant: 'warning' });
            setTimeout(() => {
              navigate('/signin'); // 重定向到登录页面
            }, 1500);
          } else {
            setLoading(false); // 设置加载状态为 false
          }
        }
      } catch (error) {
        if (isMounted) {
          enqueueSnackbar(`网络错误: ${error}`, { variant: 'error' }); // 显示网络错误通知
          setLoading(false); // 设置加载状态为 false
        }
      }
    };

    checkUserStatus(); // 调用检查用户状态函数

    return () => {
      isMounted = false; // 组件卸载时，将 isMounted 标记为 false
    };
  }, [navigate, enqueueSnackbar]); // 依赖项为 navigate 和 enqueueSnackbar

  if (loading) {
    return (<Loading />); // 如果处于加载状态，显示 Loading 组件
  }

  return children; // 否则显示子组件
};

export default UserStatus;
