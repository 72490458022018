import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <Box sx={{ flexGrow: 1, pt: 8 }}>
      <Container component="main" maxWidth="xl">
        <Grid container >
          <Grid
            item
            xs={12} sm={12} md={6}
            sx={{ p: 1, textDecoration: 'none' }}
            component={Link}
            to='/heli'
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={process.env.PUBLIC_URL + '/heli.jpg'}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" noWrap>
                    合力图册
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    安徽合力股份有限公司 Anhui Heli Co., Ltd.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6}
            sx={{ p: 1, textDecoration: 'none' }}
            component={Link}
            to='/lida'
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={process.env.PUBLIC_URL + '/lida.jpg'}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" noWrap>
                    力达图册
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    宁波力达物流设备有限公司 Ningbo Lida Logistics Equipment Co., Ltd.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6}
            sx={{ p: 1, textDecoration: 'none' }}
            component={Link}
            to='/line'
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={process.env.PUBLIC_URL + '/line.png'}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" noWrap>
                    线路图纸
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    安徽合力股份有限公司 Anhui Heli Co., Ltd.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid
            item
            xs={12} sm={12} md={6}
            sx={{ p: 1, textDecoration: 'none' }}
            component={Link}
            to='/alarm'
          >
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={process.env.PUBLIC_URL + '/alarm.png'}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" noWrap>
                    故障代码
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    合力供应商版权所有 Copyright by Heli Supplier
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}