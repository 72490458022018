import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Loading from '../components/Loading';

export default function SignUp() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword')

    if (username.length < 5) {
      enqueueSnackbar('必须输入用户名且长度不小于5位', { variant: 'error' });
      return;
    }

    if (password.length < 8) {
      enqueueSnackbar('必须输入密码且长度不小于8位', { variant: 'error' });
      return;
    }

    if (password !== confirmPassword) {
      enqueueSnackbar('两次输入密码不一致', { variant: 'error' });
      return;
    }
    try {
      setLoading(true)
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/user/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ username, password })
      });
      const signUpInfo = await response.json();
      if (response.ok) {
        enqueueSnackbar(signUpInfo.message, { variant: 'success' });
        setTimeout(() => {
          navigate('/signin');
        }, 1500);
      } else {
        enqueueSnackbar(signUpInfo.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Box sx={{ pt: 8 }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            注 册
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  fullWidth
                  id="username"
                  label="用户名"
                  name="username"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  fullWidth
                  name="password"
                  label="密码"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  fullWidth
                  name="confirmPassword"
                  label="确认密码"
                  type="Password"
                  id="confirmPassword"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label={
                    <Typography >
                      同意 &nbsp;
                      <Typography component={Link} to="/about">
                        用户协议
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color='inherit'
              sx={{ mt: 3, mb: 2 }}
            >
              注册
            </Button>
            <Grid container justifyContent="flex-end" component={Link} to='/signin'>
              <Grid item >
                已有帐户？ 点击登录
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
