import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import UserStstus from '../components/UserStatus';
import Loading from '../components/Loading';

// 列表样式
const style = {
  p: 0,
  width: '100%',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
}

function InLida() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // 网络请求数据
  const getData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/lida/data/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token })
      });
      const lidaData = await response.json();
      if (response.ok) {
        setData(lidaData)
        setIsLoading(false);
      } else {
        enqueueSnackbar('身份认证失败，请重新登录', { variant: 'error' });
        setTimeout(() => {
          navigate('/signin');
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setIsLoading(false);
    }
  }, [token, setData, setIsLoading, navigate, enqueueSnackbar]);

  // 第一次打开请求数据
  React.useEffect(() => {
    getData([])
  }, [getData])

  // 加载中...
  if (isLoading) {
    return (
      <Loading />
    );
  }

  // 选择内容
  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h6" >
          请选择要查看的图册:
        </Typography>
        <List sx={{ ...style, maxHeight: 600, overflow: 'auto', '&::-webkit-scrollbar': { display: 'none', }, }}>
          {data.lida.map((item, index) => (
            <React.Fragment key={item}>
              <ListItem disablePadding>
                <ListItemButton component="a" href={`/static/pdf/lida/${item}`} target="_blank" rel="noopener noreferrer">
                  <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                </ListItemButton>
              </ListItem>
              {index !== data.lida.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Container>
    </Box >
  );
}

export default function Lida() {
  return (
    <Container component="main" maxWidth="xl">
      <UserStstus>
        <InLida />
      </UserStstus>
    </Container>
  );
}
