import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Container,
    Paper,
    TextField,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import UserStstus from '../components/UserStatus';
import Loading from '../components/Loading';

const style = {
    p: 0,
    width: '100%',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
}

function InForkliftInfo() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = React.useState(false);
    const [forkliftData, setForkliftData] = React.useState(null);
    const [forkliftNum, setForkliftNum] = React.useState('');
    const [error, setError] = React.useState('');

    const validateForkliftNum = (num) => {
        // 验证车号格式：11位字符
        return num.length === 11;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const inputNum = data.get('forkliftNum').toUpperCase();
        setForkliftNum(inputNum);

        if (!validateForkliftNum(inputNum)) {
            enqueueSnackbar('车号必须是11位字符', { variant: 'error' });
            return;
        }

        setIsLoading(true);
        setError('');
        try {
            const csrfToken = document.cookie
                .split('; ')
                .find(row => row.startsWith('csrftoken='))
                ?.split('=')[1];
            const response = await fetch('/api/forklift-analyze/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    token,
                    FORKLIFT_NUM: inputNum
                })
            });
            const result = await response.json();

            if (response.ok) {
                setForkliftData(result);
                if (result.status === 'success' && result.basic_info?.叉车型号) {
                    enqueueSnackbar('查询成功', { variant: 'success' });
                } else {
                    setError('未查询到此车号信息，请核对后重试');
                    enqueueSnackbar('未查询到此车号信息，请核对后重试', { variant: 'error' });
                    setForkliftData(null);
                }
            } else {
                if (result.error) {
                    enqueueSnackbar(result.error, { variant: 'error' });
                } else {
                    enqueueSnackbar('身份认证失败，请重新登录', { variant: 'error' });
                    setTimeout(() => {
                        navigate('/signin');
                        window.location.reload();
                    }, 1500);
                }
            }
        } catch (error) {
            enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        setForkliftNum(value);
        setError('');
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box sx={{ pt: 8 }}>
            <Container maxWidth="md">
                <Paper sx={{ ...style, p: 4, mt: 2 }}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
                            叉车信息查询
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="forkliftNum"
                            label="叉车编号"
                            name="forkliftNum"
                            autoFocus
                            value={forkliftNum}
                            onChange={handleInputChange}
                            error={!!error}
                            helperText={error}
                            inputProps={{ maxLength: 11 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            startIcon={<SearchIcon />}
                        >
                            查询
                        </Button>
                    </Box>
                </Paper>

                {forkliftData && forkliftData.status === 'success' && forkliftData.basic_info?.叉车型号 && (
                    <>
                        <Paper sx={{ ...style, p: 2, mt: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                基本信息
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {Object.entries(forkliftData.basic_info).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                                    {key}
                                                </TableCell>
                                                <TableCell>{value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <Paper sx={{ ...style, p: 2, mt: 2 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                配置信息
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {forkliftData.config_info.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.描述}</TableCell>
                                                <TableCell>{item.值}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </>
                )}
            </Container>
        </Box>
    );
}

export default function ForkliftInfo() {
    return (
        <Container component="main" maxWidth="xl">
            <UserStstus>
                <InForkliftInfo />
            </UserStstus>
        </Container>
    );
} 