import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Fab,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import UserStstus from '../components/UserStatus';
import Loading from '../components/Loading';
import SvgComponent from '../components/Svg';

// 列表样式
const style = {
  p: 0,
  width: '100%',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
}

// 对应显示文本
const keyTextMap = {
  series: "请选择系列:",
  scope: "请选择吨位范围:",
  model: "请选择车型:",
  tonnage: "请选择吨位:",
  config: "请选择配置:",
  category: "请选择系统:",
  assembly: "请选择总成:",
};

function InHeli() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selections, setSelections] = React.useState([]);
  const [currentKey, setCurrentKey] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  // 搜索弹窗打开
  const handleClickOpen = () => {
    setOpen(true);
  };

  // 搜索弹窗关闭
  const handleClose = () => {
    setOpen(false);
  };

  // 网络请求数据
  const getData = React.useCallback(async (select) => {
    setIsLoading(true);
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/heli/data/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token, select })
      });
      const heliData = await response.json();
      if (response.ok) {
        setData(heliData)
        setCurrentKey(Object.keys(heliData)[0])
        setIsLoading(false);
      } else {
        enqueueSnackbar('身份认证失败，请重新登录', { variant: 'error' });
        setTimeout(() => {
          navigate('/signin');
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setIsLoading(false);
    }
  }, [token, setData, setCurrentKey, setIsLoading, navigate, enqueueSnackbar]);

  // 第一次打开请求数据
  React.useEffect(() => {
    getData([])
  }, [getData])

  // Home按钮回调函数
  const handleHomeClick = React.useCallback(async () => {
    setSelections([]);
    getData([]);
  }, [setSelections, getData]);

  // 路径回调函数
  const handlePathClick = React.useCallback((key) => {
    const pathIndex = selections.findIndex(selection => selection.key === key);
    const select = selections.slice(0, pathIndex + 1);
    setSelections(select);
    getData(select);
  }, [selections, setSelections, getData]);

  // 主页面列表回调函数
  const handleMainClick = React.useCallback((key, value) => {
    const select = [...selections, { key, value }];
    setSelections(select);
    if (select.length <= Object.keys(keyTextMap).length + 1) {
      getData(select)
    }
  }, [selections, setSelections, getData]);

  // 搜索回调
  const handleLaunch = React.useCallback((alarm) => {
    const select = (
      [
        { key: 'series', value: alarm.series__series },
        { key: 'scope', value: alarm.series__scope },
        { key: 'model', value: alarm.machine_model__model },
        { key: 'tonnage', value: alarm.tonnage__tonnage },
        { key: 'config', value: alarm.config__config_number }
      ]
    )
    setSelections(select);
    getData(select)
    setOpen(false)
  }, [setSelections, getData, setOpen]);

  // 旧版本图册回调
  const handleOldManualClick = async () => {
    setIsLoading(true);
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/heli/data/old/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token })
      });
      const heliData = await response.json();
      if (response.ok) {
        setData(heliData)
        setIsLoading(false);
      } else {
        enqueueSnackbar('身份认证失败，请重新登录', { variant: 'error' });
        setTimeout(() => {
          navigate('/signin');
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setIsLoading(false);
    }
  }

  // 路径导航
  function HeliNav() {
    return (
      <Box sx={{ mt: 1, width: '100%' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={style}>
          <Button color="inherit" onClick={handleHomeClick} sx={{ minWidth: 0 }}>
            <HomeIcon sx={{ mb: 0.5, ml: 0.5 }} />
          </Button>
          {selections.map((selection, index) => (
            <Button
              color="inherit"
              onClick={() => handlePathClick(selection.key)}
              key={selection.key}
              sx={{ minWidth: 0 }}
              disabled={index === selections.length - 1}
            >
              {selection.value}
            </Button>
          ))}
        </Breadcrumbs>
        <React.Fragment>
          <Box sx={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}>
            <Fab color="error" aria-label="search" onClick={handleClickOpen}>
              <SearchIcon />
            </Fab>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              onSubmit: async (event) => {
                event.preventDefault();
                const data = new FormData(event.currentTarget);
                const searchContent = data.get('search');
                const searchType = 'heli'
                try {
                  setIsLoading(true)
                  const csrfToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('csrftoken='))
                    ?.split('=')[1];
                  const response = await fetch(`/api/search/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRFToken': csrfToken,
                    },
                    body: JSON.stringify({ searchContent, searchType, token })
                  });
                  const searchResponse = await response.json();
                  if (response.ok) {
                    setData(searchResponse)
                    setIsLoading(false);
                  } else {
                    enqueueSnackbar(searchResponse.message, { variant: 'error' });
                    setIsLoading(false);
                  }
                } catch (error) {
                  enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
                  setIsLoading(false);
                }
              },
            }}
          >
            <DialogTitle>搜索</DialogTitle>
            <DialogContent>
              <DialogContentText>
                请输入你要搜索的配置号(只输入配置号即可,可以少输入但不要多输入):
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="search"
                name="search"
                type="search"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <IconButton onClick={handleClose} color='error'><CloseIcon /></IconButton>
              <IconButton type="submit" color='success'><CheckIcon /></IconButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Box>
    )
  }

  // 加载中...
  if (isLoading) {
    return (
      <Loading />
    );
  }

  // 搜索显示列表
  if (Object.keys(data)[0] === 'heli_search') {
    return (
      <Box sx={{ pt: 10 }}>
        <Paper sx={{ ...style, mt: 1 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 80 }}>系列</TableCell>
                  <TableCell align='center' style={{ minWidth: 80 }}>范围</TableCell>
                  <TableCell align='center' style={{ minWidth: 80 }}>车型</TableCell>
                  <TableCell align='center' style={{ minWidth: 80 }}>吨位</TableCell>
                  <TableCell align='center' style={{ minWidth: 80 }}>配置号</TableCell>
                  <TableCell align='center' style={{ minWidth: 80 }}>跳转</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.heli_search.map((alarm) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={alarm.machine_model__model + alarm.tonnage__tonnage + alarm.config__config_number}>
                    <TableCell align='center' >{alarm.series__series}</TableCell>
                    <TableCell align='center' >{alarm.series__scope}</TableCell>
                    <TableCell align='center' >{alarm.machine_model__model}</TableCell>
                    <TableCell align='center' >{alarm.tonnage__tonnage}</TableCell>
                    <TableCell align='center' >{alarm.config__config_number}</TableCell>
                    <TableCell align='center' >
                      <IconButton
                        color="error"
                        onClick={() => handleLaunch(alarm)}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  // 旧版手册列表
  if (Object.keys(data)[0] === 'heli_old_manual') {
    return (
      <Box sx={{ pt: 10 }}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h6" >
            请选择要查看的内容:
          </Typography>
          <List sx={{ ...style, maxHeight: 600, overflow: 'auto', '&::-webkit-scrollbar': { display: 'none', }, }}>
            {data.heli_old_manual.map((item, index) => (
              <React.Fragment key={item}>
                <ListItem disablePadding>
                  <ListItemButton component="a" href={`/static/pdf/heli/${item}`} target="_blank" rel="noopener noreferrer">
                    <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                  </ListItemButton>
                </ListItem>
                {index !== data.heli_old_manual.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </Container>
      </Box >
    );
  }

  // 手册详情页
  if (Object.keys(data)[0] === 'parts') {
    return (
      <Box sx={{ pt: 8 }}>
        <HeliNav />
        <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <Paper sx={{ ...style, mt: 1, height: 600, bgcolor: '#ffffff' }}>
              <SvgComponent partNumber={data['parts'][0].part_number} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper sx={{ ...style, mt: 1, height: 600 }}>
              <TableContainer sx={{ height: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align='center' style={{ minWidth: 70 }}>序号</TableCell>
                      <TableCell align='center' style={{ minWidth: 150 }}>图号</TableCell>
                      <TableCell align='center' style={{ minWidth: 70 }}>版本</TableCell>
                      <TableCell align='center' style={{ minWidth: 200 }}>名称</TableCell>
                      <TableCell align='center' style={{ minWidth: 70 }}>件数</TableCell>
                      <TableCell align='center' style={{ minWidth: 240 }}>备注</TableCell>
                      <TableCell align='center' style={{ minWidth: 100 }}>适用日期</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[currentKey].map((part) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={part.item_number + part.part_number}>
                        <TableCell align='center' >{part.item_number}</TableCell>
                        <TableCell align='center' >{part.part_number}</TableCell>
                        <TableCell align='center' >{part.edition}</TableCell>
                        <TableCell align='center' >{part.name_zh}</TableCell>
                        <TableCell align='center' >{part.quantity}</TableCell>
                        <TableCell align='center' >{part.remark}</TableCell>
                        <TableCell align='center' >{part.applicable_date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    )
  }

  // 选择内容
  return (
    <Box sx={{ pt: 8 }}>
      <HeliNav />
      <Container maxWidth="sm">
        {selections.length === 0 && (  // 只在没有选择时(首页)显示单文件图册
          <>
            <Typography component="h1" variant="h6" >
              {'单文件图册:'}
            </Typography>
            <List sx={{ ...style }}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleOldManualClick()}>
                  <ListItemText primary={'旧版PDF图册'} sx={{ textAlign: 'center' }} />
                </ListItemButton>
              </ListItem>
            </List>
          </>
        )}
        <Typography component="h1" variant="h6" >
          {keyTextMap[currentKey]}
        </Typography>
        <List sx={{ ...style, maxHeight: 600, overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' }, }}>
          {data[currentKey].map((item, index) => (
            currentKey === 'assembly' ? (
              <React.Fragment key={`${item.part_number} ${item.name}`}>
                <ListItem disablePadding>
                  {item.svg ? (
                    <ListItemButton onClick={() => handleMainClick(currentKey, item.part_number)}>
                      <ListItemText primary={`${item.part_number} ${item.name}`} sx={{ textAlign: 'center' }} />
                    </ListItemButton>
                  ) : (
                    <ListItemButton component="a" href={`/static/pdf/heli/assembly/${item.part_number}.pdf`} target="_blank" rel="noopener noreferrer">
                      <ListItemText primary={`${item.part_number} ${item.name}`} sx={{ textAlign: 'center' }} />
                    </ListItemButton>
                  )}
                </ListItem>
                {index !== data[currentKey].length - 1 && <Divider component="li" />}
              </React.Fragment>
            ) : (
              <React.Fragment key={item}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleMainClick(currentKey, item)}>
                    <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                  </ListItemButton>
                </ListItem>
                {index !== data[currentKey].length - 1 && <Divider component="li" />}
              </React.Fragment>
            )
          ))}
        </List>
      </Container>
    </Box >
  );
}

export default function Heli() {
  return (
    <Container component="main" maxWidth="xl">
      <UserStstus>
        <InHeli />
      </UserStstus>
    </Container>
  );
}
