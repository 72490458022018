import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from '../components/Loading';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function AccordionExpandDefault() {
  const [accordionData, setAccordionData] = useState([]);

  useEffect(() => {
    fetch(`/api/announcements/data/`)
      .then(response => response.json())
      .then(data => setAccordionData(data))
      .catch(error => console.error('Error apiFetching data:', error));
  }, []);

  return (
    <Box component="main" sx={{ pt: 10 }}>
      <Container maxWidth="xl">
        {accordionData.announcements_data ? (
          accordionData.announcements_data.map((item, index) => (
            <Accordion key={item.date}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={item.content}
                id={item.date}
              >
                <Typography>{item.date}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.content}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Loading />
        )}
      </Container>
    </Box>
  );

}
